import Vue from 'vue';
export default{
    state:{
        oInfoUsuario:{},
        oInfoUsuarioBuscado:{},
        oDatosGeneral:{},
        aClasesProximas:[],
        aClasesReservadas:[],
        aClasesRealizadas:[],
        aHistorialProgreso:[],
        sFechaReAgendar:'',
        bValidaPagosPendiente:false,
        aClientes:[]
    },
    getters:{
        getSesionIniciada(state){
            return Object.keys(state.oInfoUsuario).length>0;
        },
        getInfoCliente(state){
            return state.oInfoUsuario;
        },
        getInfoClienteBuscado(state){
            return state.oInfoUsuarioBuscado;
        },
        getInfoDatosGeneral(state){
            return state.oDatosGeneral;
        },
        getUserType(state){
            return state.oInfoUsuario['TipoUsuario'] || '';
        },
        getUserName(state){
            return state.oInfoUsuario['Nombre'] || '';
        },
        getUserLastName(state){
            return state.oInfoUsuario['Apellido'] || '';
        },
        getUserFullName(state){
            return state.oInfoUsuario['Nombre'] + ' '+state.oInfoUsuario['Apellido'];
        },
        getPayedClass(state){
            return Object.keys(state.oInfoUsuarioBuscado).length>0 ? state.oInfoUsuarioBuscado['Pagadas'] : state.oInfoUsuario['Pagadas'];
        },
        getClasesReservadas(state){
            return state.aClasesReservadas;
        },
        getClasesRealizadas(state){
            return state.aClasesRealizadas;
        },
        getHistorialProgreso(state){
            return state.aHistorialProgreso;
        },
        getClasesProximas(state){
            return state.aClasesProximas;
        },
        getFechaReAgendar(state){
            return state.sFechaReAgendar;
        },
        getValidaPagosPendiente(state){
            return state.bValidaPagosPendiente;
        },
        getClientes(state){
            return state.aClientes;
        }
    },
    mutations:{
        setUserInfo(state, oUserInfo){
            state.oInfoUsuario = oUserInfo
        },
        setSearchUserInfo(state, oUserInfo){
            state.oInfoUsuarioBuscado = oUserInfo
        },
        setChangeSearchUserInfo(state, aCambios){
            aCambios.forEach(oDato=>{
                Vue.set(state.oInfoUsuarioBuscado, oDato.Elemento, oDato.Valor);
            })
        },
        setGeneralData(state, oDatos){
            state.oDatosGeneral = oDatos;
        },
        setUserClases(state, aDatos){
            //limpia historial
            state.aClasesRealizadas=[];
            state.aClasesReservadas=[];
            aDatos.forEach(oClase => {
                if(oClase.Estado==1){//agrega dependiendo del dato
                    Vue.set(state.aClasesReservadas, state.aClasesReservadas.length, oClase);
                }
                else if(oClase.Estado==0){
                    Vue.set(state.aClasesRealizadas, state.aClasesRealizadas.length, oClase);
                }
            });
        },
        setUserProgress(state, aDatos){
            state.aHistorialProgreso=[];
            aDatos.forEach(oProgeso => {//almacena registro de progreso
                Vue.set(state.aHistorialProgreso, state.aHistorialProgreso.length, oProgeso);
            });
        },
        setNextClases(state, aDatos){
            state.aClasesProximas=[];
            aDatos.forEach(oClase => {//almacena registro de progreso
                Vue.set(state.aClasesProximas, state.aClasesProximas.length, oClase);
            });
        },
        setCloseSesion(state){
            state.oInfoUsuario={};
            state.oInfoUsuarioBuscado={};
            state.oDatosGeneral={};
            state.aClasesProximas=[];
            state.aClasesReservadas=[];
            state.aClasesRealizadas=[];
            state.aHistorialProgreso=[];
            state.bValidaPagosPendiente=false;
        },
        setDateModify(state, sDate){
            state.sFechaReAgendar = sDate;
        },
        setValidaPagosPendientes(state, bValidaPagos){
            state.bValidaPagosPendiente = bValidaPagos;
        },
        setClients(state, aDatos){
            state.aClientes = aDatos;
        }
    },
    actions:{
        SET_USER_INFO(context, oUserInfo){
            context.commit('setUserInfo', oUserInfo);
        },
        SET_SEARCH_USER_INFO(context, oUserInfo){
            context.commit('setSearchUserInfo', oUserInfo);
        },
        SET_USER_CLASES(context, aDatos){
            context.commit('setUserClases', aDatos);
        },
        SET_USER_PROGRESS(context, aDatos){
            context.commit('setUserProgress', aDatos);
        },
        SET_NEXT_CLASES(context, aDatos){
            context.commit('setNextClases', aDatos);
        },
        SET_GENERAL_DATA(context, oDatos){
            context.commit('setGeneralData', oDatos);
        },
        SET_CLOSE_SESION(context){
            context.commit('setCloseSesion');
        },
        SET_CHANGE_SEARCH_USERINFO(context, aDatos){
            context.commit('setChangeSearchUserInfo', aDatos);
        },
        SET_DATE_MODIFY(context, sDate){
            context.commit('setDateModify', sDate);
        },
        SET_VALIDATE_PAYMENT(context, bValidaPagos){
            context.commit('setValidaPagosPendientes', bValidaPagos);
        },
        SET_CLIENTS(context, aDatos){
            context.commit('setClients', aDatos);
        }
    }  
}