import Vue from 'vue';
export default{
    state:{
        sFechaHoy:'',
        sFechaMax:'',
        sFechaDeseada:'',
        aDatosCitasBuscada:[],
        aFechasOcupadas:[]
    },
    getters:{
        getFechaHoy(state){
            return state.sFechaHoy;
        },
        getFechaMax(state){
            return state.sFechaMax;
        },
        getDatosCitas(state){
            return state.aDatosCitasBuscada;
        },
        getFechasOcupadas(state){
            return state.aFechasOcupadas;
        },
        getFechaDeseada(state){
            return state.sFechaDeseada;
        }
    },
    mutations:{
        setTodayDate(state, sDato){
            state.sFechaHoy = sDato
        },
        setMaxDate(state, sDato){
            state.sFechaMax = sDato
        },
        setWantedDate(state, sDato){
            state.sFechaDeseada = sDato
        },
        setAppointmentData(state, aDatos){
            state.aDatosCitasBuscada=[];
            aDatos.forEach(oFecha => {
                Vue.set(state.aDatosCitasBuscada, state.aDatosCitasBuscada.length, oFecha);
            });
        },
        setUnavailableDates(state, aDatos){
            state.aFechasOcupadas=[];
            aDatos.forEach(oFecha => {
                Vue.set(state.aFechasOcupadas, state.aFechasOcupadas.length, oFecha.Fecha);
            });
        }
    },
    actions:{
        SET_TODAY_DATE(context, sDato){
            context.commit('setTodayDate', sDato);
        },
        SET_MAX_DATE(context, sDato){
            context.commit('setMaxDate', sDato);
        },
        SET_WANTED_DATE(context, sDato){
            context.commit('setWantedDate', sDato);
        },
        SET_APPOINTMENT_DATA(context, aDatos){
            context.commit('setAppointmentData', aDatos);
        },
        SET_UNAVAILABLE_DATES(context, aDatos){
            context.commit('setUnavailableDates', aDatos);
        }
    }
}