import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartArea, faTags, faTruck, faList, faCog, faLifeRing, faPencilAlt, faUserTag, faUser, faUsers, faArrowLeft, faArrowUp, faArrowDown, faArrowRight, faTimes, faBookReader, faEye, faDollarSign, faCashRegister, faWindowClose, faExchangeAlt, faMinus, faPlus, faEquals, faShoppingCart, faCartPlus, faTrashAlt, faPrint} from '@fortawesome/free-solid-svg-icons'
import { faFileAlt, faAngry, faFrown, faMeh, faSmile, faGrinAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueSignaturePad from 'vue-signature-pad';
import HighchartsVue from 'highcharts-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


library.add(faChartArea, faTags, faTruck, faList, faCog, faLifeRing, faPencilAlt, faUserTag, faUser, faUsers, faArrowLeft, faArrowUp, faArrowDown, faArrowRight, faFileAlt, faTimes, faBookReader, faEye, faDollarSign, faCashRegister, faWindowClose, faExchangeAlt, faMinus, faPlus, faEquals, faShoppingCart, faCartPlus, faTrashAlt, faPrint, faAngry, faFrown, faMeh, faSmile, faGrinAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.use(VueSignaturePad);
Vue.use(HighchartsVue);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
