/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
export default{
    state:{
        showDialog:false,
        showLoader:false,
        showDialogBtn:false,
        showDialogBtnSecondary:false,
        fFunDialogEnd:null,
        fFunDialogEndSecondary:null,
        dialogMsg:'',
        sTitle:'',
        sBtnMainText:'Aceptar',
        sBtnSecondaryText:'Cancelar',
    },
    getters:{
        getDialogTitle(state){
            return state.sTitle == '' ? 'Aviso' : state.sTitle;
        },
        getShowDialog(state){
            return state.showDialog;
        },        
        getDialogMsg:function (state) {
            return state.dialogMsg;
        },
        getShowLoader:function (state) {
            return state.showLoader;
        },
        getShowBtn:function (state) {
            return state.showDialogBtn;
        },
        getShowBtnSecondary:function (state) {
            return state.showDialogBtnSecondary;
        },
        getShowCodigoUsuario:function(state){
            return state.showCodigoUsuario;
        },
        getFunctionDialogEnd:function(state){
            return state.fFunDialogEnd;
        },
        getFunctionDialogEndSecondary:function(state){
            return state.fFunDialogEndSecondary;
        },
        getBtnMainText(state){
            return state.sBtnMainText == '' ? 'Aceptar' : state.sBtnMainText;
        },
        getBtnSecondaryText(state){
            return state.sBtnSecondaryText == '' ? 'Cancelar' : state.sBtnSecondaryText;
        }
    },
    mutations:{
        changeDialogStatus(state, bStatus){
            state.showDialog = bStatus;
        },
        changeDialogTitle(state, sValue){
            state.sTitle = sValue;
        },
        changeBtnMainText(state, sValue){
            state.sBtnMainText = sValue;
        },
        changeBtnSecondaryText(state, sValue){
            state.sBtnSecondaryText = sValue;
        },
        changeDialogLoaderStatus(state, bStatus){
            state.showLoader = bStatus;
        },
        changeDialogMsg(state, msgText){
            state.dialogMsg = msgText;
        },
        changeDialogBtnStatus(state, bStatus){
            state.showDialogBtn = bStatus;
        },
        changeDialogBtnSecondaryStatus(state, bStatus){
            state.showDialogBtnSecondary = bStatus;
        },
        setFuncDialogEnd(state, fFunc){
            state.fFunDialogEnd = fFunc;
        },
        setFuncDialogEndSecondary(state, fFunc){
            state.fFunDialogEndSecondary = fFunc;
        }
    },
    actions:{
        changeDialogTitle(context, sValue){
            context.commit('changeDialogTitle', sValue);
        },
        changeBtnMainText(context, sValue){
            context.commit('changeBtnMainText', sValue);
        },
        changeBtnSecondaryText(context, sValue){
            context.commit('changeBtnSecondaryText', sValue);
        },
        changeDialogStatus(context, bStatus){
            context.commit('changeDialogStatus', bStatus);
        },
        changeDialogLoaderStatus(context, bStatus){
            context.commit('changeDialogLoaderStatus', bStatus);
        },
        changeDialogBtnStatus(context, bStatus){
            context.commit('changeDialogBtnStatus', bStatus);
        },
        changeDialogBtnSecondaryStatus(context, bStatus){
            context.commit('changeDialogBtnSecondaryStatus', bStatus);
        },
        changeDialogMsg(context, msgText){
            context.commit('changeDialogMsg', msgText);
        },
        setFuncDialogEnd(context, fFunDialogEnd){
            context.commit('setFuncDialogEnd', fFunDialogEnd);
        },
        setFuncDialogEndSecondary(context, fFunDialogEnd){
            context.commit('setFuncDialogEndSecondary', fFunDialogEnd);
        }
    }
}