<template>
    <footer>
        <div class="CtnFil">
            <div class="CtnFil centrado Logo">
                <img src="../assets/ImagenesPagina/LOGO FONDO OSCURO.png" alt="TecnoForce">
            </div>
            <div class="CtnFil Redes">
                <img class="Btn" src="../assets/Iconos/Frame 1.png" alt="Facebook" @click="AccesoFace">
                <img class="Btn" src="../assets/Iconos/Frame 5.png" alt="Contacto" @click="AccesoTel">
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name:'tecno-footer',
    methods:{
        AccesoFace:function(){
            window.open('https://www.facebook.com/tecnoforceoficial');
        },
        AccesoTel:function(){
            window.open("https://api.whatsapp.com/send?phone=528442971714");
        }
    }
}
</script>

<style scoped>
    footer{
        width: 100%;
        height: 80px;
        background-color: var(--base-oscuro);
    }
    .CtnFil{
        height: 100%;
        justify-content: space-between;
    }
    
    .Logo, .Redes{
        height: 100%;
        align-items: center;
    }

    .Logo img{
        width: 260px;
        max-height: 60px;
    }

    .Redes img{
        width: 70px;
        padding: 0 12px;
        transition: 0.2s filter linear;
    }

    .Redes img:hover{
        filter: drop-shadow(2px 2px 1px rgba(182, 182, 182, 0.8));
    }

    @media all and (max-device-width: 900px), (max-width: 650px){
        .Logo img{
            width: 160px;
        }

        .Redes img{
            width: 40px;
            padding: 0 12px;
        }
    }
</style>