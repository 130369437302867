export default{
    state:{
        // sConektaPublicKey:'key_K2SWaThgdMpdOE6plJTfvZF',
        // sHttpConexion: 'http://localhost/tecnoforce/',

        sHttpConexion: 'https://tecnoforce.com.mx/',
        sConektaPublicKey:'key_U8DRjTIOw7AVcaxOeKit5Yo',
    },
    getters:{
        getEnlaceConexion(state){
            return state.sHttpConexion;
        },
        getConektaPublicKey(state){
            return state.sConektaPublicKey;
        }
    }
}