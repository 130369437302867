import Vue from 'vue'
import Router from 'vue-router'
import Inicio from './views/Inicio.vue'


Vue.use(Router)
/*eslint no-console: ["error", { allow: ["warn","log"] }] */
const newRoute = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Inicio',
      alias: '/Inicio',
      props: { main: 'Inicio', nombreOpciones:'Inicio'},
      component: Inicio
    },
    { path: '*', 
      component: Inicio
    },
    {
      path: '/Registro',
      name: 'Registro',
      props: { main: 'Registro', nombreOpciones:'Registro'},
      component: () => import('@/views/Registro.vue')
    },
    {
      path: '/IniciarSesion',
      name: 'IniciarSesion',
      props: { main: 'IniciarSesion', nombreOpciones:'IniciarSesion'},
      component: () => import('@/views/IniciarSesion.vue')
    },
    {
      path: '/InicioUsuario',
      name: 'InicioUsuario',
      props: { main: 'InicioUsuario', nombreOpciones:'InicioUsuario'},
      component: () => import('@/views/InicioUsuario.vue')
    },
    {
      path: '/InicioEntrenador',
      name: 'InicioEntrenador',
      props: { main: 'InicioEntrenador', nombreOpciones:'InicioEntrenador'},
      component: () => import('@/views/InicioEntrenador.vue')
    },
    {
      path: '/InicioAdministrador',
      name: 'InicioAdministrador',
      props: { main: 'InicioAdministrador', nombreOpciones:'InicioAdministrador'},
      component: () => import('@/views/InicioAdministrador.vue')
    },
    {
      path: '/Calendario',
      name: 'Calendario',
      props: { main: 'Calendario', nombreOpciones:'Calendario'},
      component: () => import('@/views/Calendario.vue')
    },
    {
      path: '/Compras',
      name: 'Compras',
      props: { main: 'Compras', nombreOpciones:'Compras'},
      component: () => import('@/views/Compras.vue')
    },
    {
      path: '/Nosotros',
      name: 'Nosotros',
      props: { main: 'Nosotros', nombreOpciones:'Nosotros'},
      component: () => import('@/views/Nosotros.vue')
    },
    {
      path: '/Estado',
      name: 'Estado',
      props: { main: 'Estado', nombreOpciones:'Estado'},
      component: () => import('@/views/Estado.vue')
    },
    {
      path: '/Paquetes',
      name: 'Paquetes',
      props: { main: 'Paquetes', nombreOpciones:'Paquetes'},
      component: () => import('@/views/Paquetes.vue')
    },
    {
      path: '/Clientes',
      name: 'Clientes',
      props: { main: 'Clientes', nombreOpciones:'Clientes'},
      component: () => import('@/views/Clientes.vue')
    },
    {
      path: '/RecuperarUsuario',
      name: 'RecuperarUsuario',
      props: { main: 'RecuperarUsuario', nombreOpciones:'RecuperarUsuario'},
      component: () => import('@/views/RecuperarUsuario.vue')
    },
    {
      path: '/Bitacora',
      name: 'Bitacora',
      props: { main: 'Bitacora', nombreOpciones:'Bitacora'},
      component: () => import('@/views/Bitacora.vue')
    },
    {
      path: '/EncuestaSatisfaccion',
      name: 'EncuestaSatisfaccion',
      props: { main: 'EncuestaSatisfaccion', nombreOpciones:'EncuestaSatisfaccion'},
      component: () => import('@/views/EncuestaSatisfaccion.vue')
    }
  ]
})


// newRoute.beforeEach((to, from, next) => {
// 	reiniciaOpciones();
//   // Antes de ingresar a una pagina valida si tiene permiso
//   anylizeRedirect(to, function(bPermiso, sPaginaPermitida){
//     if(!bPermiso)
//       next(sPaginaPermitida);
//     else
//       next();
//   });
// })

// function reiniciaOpciones(){
// 	store.dispatch('SET_FILTER_LOADED', false);
// 	store.dispatch('SET_OPTIONS_LOADED', false);	
// }

// function anylizeRedirect(toPath, fFunct) {
// 	if (!store.getters.getMainMenu.hasOwnProperty('Productos')) {//Si aun no se actualiza la informacion del menu
// 		setTimeout(function () {
// 			anylizeRedirect(toPath, fFunct);
// 		}, 200);
// 	} else {
// 		let oMenuPrincipal = store.getters.getMainMenu,
// 			sSubmenuMain = toPath.matched[0].props.default.main,
// 			sPaginaPermitida = '',
// 			bPermiso = false;
// 		for (let sMenu in oMenuPrincipal) {//Loop opciones de menu
// 			if (oMenuPrincipal[sMenu].Ruta == toPath.fullPath || sSubmenuMain == sMenu) {// valida si es la pagina que se quiere accesar o es parte del submenu
// 				if((!oMenuPrincipal[sMenu].Disabled && oMenuPrincipal[sMenu].Visible) || (toPath.fullPath=='/' || toPath.fullPath=='/Inicio')) bPermiso = true;
// 			}

// 			if (!oMenuPrincipal[sMenu].Disabled && oMenuPrincipal[sMenu].Visible && sPaginaPermitida == ''){//Selecciona la primera pagina con acceso
// 				sPaginaPermitida = oMenuPrincipal[sMenu].Ruta;
// 			}
// 		}
		
// 		fFunct(bPermiso, sPaginaPermitida);
// 	}
// }

export default newRoute;