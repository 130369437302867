<template>
    <div class="Servicios">
        <div class="Principal CtnCol">
            <div class="Titulo centrado">NUESTROS SERVICIOS</div>
            <div class="Contenido centrado">
                Centro de acondicionamiento físico que trabaja con electroestimulación
                y que consiste en activar el 90% de los músculos del cuerpo en poco
                tiempo a una intensidad que no se logra en otro deporte.
            </div>
        </div>
        <div class="Opciones CtnFil">
            <div class="Col CtnCol">
                <div class="ImgSer">
                    <img src="../assets/ImagenesPagina/Usain.jpg" alt="Entrenamiento"
                        srcset="../assets/ImagenesPagina/Usain_med.jpg 600w" sizes="(max-width: 950px) 600px">
                </div>
                <div class="Cont-Titulos">
                    <div class="Titulo centrado">Entrenamiento con Electro Estimulación</div>
                </div>
                <div class="Contenido centrado">
                    Entrenamiento personalizado de 20 min con tecnología alemana que
                    activa mas de 300 músculos en forma simultanea, los cuales
                    equivalen a 5 días de entrenamiento en un gym normal. El objetivo es
                    fortalecer, tonificar y desplazar grasa 3 veces más
                    rápido de los normal.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'servicios-inicio'
}
</script>
<style scoped>
.Servicios {
    width: 100%;
    padding: 60px 0 0;
}


.Principal {
    padding: 0 0 40px;
}

.Principal .Titulo {
    width: 100%;
    font-size: 6vw;
    font-family: 'Bebas';
    color: var(--base-naranja);
}

.Principal .Contenido {
    padding: 0 16vw;
    font-size: 2vw;
}

.Opciones {
    padding: 0 9.5vw 40px;
    justify-content: center;
}

.Opciones .Cont-Titulos {
    height: 110px;
}

.Opciones .Col {
    width: calc(50% - 4vw);
    padding: 0 2vw;
}

.Opciones .ImgSer {
    width: 100%;
    height: 200px;
    overflow: hidden;
    text-align: center;
    box-shadow: 1px 1px 4px rgba(80, 80, 80, 0.8);
    display: flex;
    align-items: center;
    background-color: #e5e5e5;
}

.Opciones .ImgSer img {
    width: 100%;
}

.Opciones .Titulo,
.Opciones .Subtitulo {
    font-size: 42px;
    font-family: 'Bebas';
    color: var(--base-naranja);
}

.Opciones .Subtitulo {
    font-size: 18px;
}

.Opciones .Contenido {
    font-size: 1.3vw;
}

@media all and (max-device-width: 950px),
(max-width: 650px) {
    .Principal .Titulo {
        font-size: 12vw;
    }

    .Principal .Contenido {
        padding: 0 2vw;
        font-size: 4vw;
    }

    .Opciones {
        flex-direction: column;
    }

    .Opciones .Col {
        width: 100%;
        padding: 8px 0;
    }

    .Opciones .Cont-Titulos {
        height: auto;
    }

    .Opciones .Titulo,
    .Opciones .Subtitulo {
        font-size: 28px;
        padding: 4px 0;
    }

    .Opciones .Subtitulo {
        font-size: 18px;
    }

    .Opciones .Contenido {
        font-size: 4vw;
    }
}
</style>