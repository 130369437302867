<template>
	<nav class="SinSeleccionTexto">
		<div class="Envuelve-Cont-Sombra"><div class="Contiene-Corta"></div></div>
		<div class="Contiene-Elementos">
			<div class="Logo">
				<div class="Envuelve-Cont">
					<div class="Logo-Fondo">
						<img src="../assets/ImagenesPagina/LOGO.png" alt="TecnoForce">
					</div>
				</div>
			</div>
			<div ref="MenuNav" class="MenuNav" @click="MuestraEscondeMenu" v-if="currentRouteName!='EncuestaSatisfaccion'">
				<div class="Opciones" v-if="nTipoUsuario==2">
					<router-link to="/InicioEntrenador" >INICIO</router-link>
					<router-link to="/Calendario" >AGENDAR</router-link>
					<router-link to="/Nosotros" >NOSOTROS</router-link>
					<router-link to="/Bitacora" >BITACORA</router-link>
				</div>
				<div class="Opciones" v-else-if="nTipoUsuario==3">
					<router-link to="/InicioAdministrador" >SESIONES</router-link>
					<router-link to="/Calendario" >AGENDAR</router-link>
					<router-link to="/Paquetes" >PAQUETES</router-link>
					<router-link to="/Clientes" >CLIENTES</router-link>
					<router-link to="/Estado" >ESTADO</router-link>
				</div>
				<div class="Opciones" v-else>
					<router-link to="/" >INICIO</router-link>
					<router-link to="/Calendario" >AGENDAR</router-link>
					<router-link to="/Nosotros" >NOSOTROS</router-link>
				</div>
				<div class="Sesion" v-if="!bSesionIniciada">
					<router-link to="/IniciarSesion" class="gs-nav-item-action">INICIAR SESIÓN</router-link>
					|
					<router-link to="/Registro" class="gs-nav-item-action">REGISTRATE</router-link>
				</div>
				<div class="Sesion Iniciado derecha" v-if="bSesionIniciada" @click="RedirigeUsuario">
					<a class="gs-nav-item-action">{{ sNombreUsuario }}</a>
				</div>
			</div>
			<div class="Btn Menu" @click="MuestraEscondeMenu">
				<img src="../assets/Iconos/Frame 4.png" alt="Menu">
			</div>
		</div>

	</nav>
</template>

<script>
export default {
	methods:{
		MuestraEscondeMenu:function(){
			let $MenuNav = this.$refs.MenuNav,
				sClaseActual = $MenuNav.className;
			sClaseActual=='MenuNav' ? $MenuNav.className='MenuNav Muestra' : $MenuNav.className='MenuNav';
		},
		RedirigeUsuario: function(){
			if(this.nTipoUsuario==1 && this.$router.history.current.name!='InicioUsuario')
				this.$router.push({ name: 'InicioUsuario' });
			else if(this.nTipoUsuario==2 && this.$router.history.current.name!='InicioEntrenador'){
				this.$router.push({ name: 'InicioEntrenador' });
			}
			else if(this.nTipoUsuario==3 && this.$router.history.current.name!='InicioAdministrador'){
				this.$router.push({ name: 'InicioAdministrador' });
			}
		}
	},
	computed:{
		bSesionIniciada:{
			get(){
				return this.$store.getters.getSesionIniciada;
			}
		},
		sNombreUsuario:{
			get(){
				return this.$store.getters.getUserFullName;
			}
		},
		nTipoUsuario:{
			get(){
				return this.$store.getters.getUserType;
			}
		},
		currentRouteName() {
			return this.$route.name;
		}
	}
}
</script>

<style scoped>
	

	.Contiene-Elementos{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 80px;
		z-index: 1;
	}
	.Envuelve-Cont-Sombra{
		position: absolute;
		right: 0;
		width: 90%;
		filter: drop-shadow(-1px 2px 3px rgba(0, 0, 0, 0.6));
		z-index: 0;
	}
	.Contiene-Corta{
		position: absolute;
		right: 0;
		width: 100%;
		height: 95px;
		background-color: #ffffff;
		-webkit-clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%);;
		clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%);;
	}

		.MenuNav{
			z-index: 1;
			width: 100%;
			height: 95px;
			display: flex;
			justify-content: space-between;
		}

		.Logo, .Opciones, .Sesion{
			z-index: 1;
		} 
			.Envuelve-Cont{
				filter: drop-shadow(-1px 2px 3px rgba(0, 0, 0, 0.6));
			}
			.Logo-Fondo{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 350px;
				height: 80px;
				background-color: var(--base-naranja);
				-webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
				clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
			}
			.Logo img{
				width: 260px;
			}

		.Opciones{
			width: 70%;
			display: flex;
			justify-content: space-around;
			align-items: center;
		}

			.Contiene-Elementos a{
				font-family: 'Bebas';
				text-decoration: none;
				color: #000000;
				padding: 0 8px;
				transition: 0.2s text-shadow linear;
			}
				.Contiene-Elementos a:hover{
					text-shadow: 0 0 6px rgba(225, 82, 41, 0.6);
				}

			.Opciones a{
				font-size: 3vw;
			}

			.Opciones a.router-link-exact-active{
				color: var(--base-naranja);
			}

		.Sesion{
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.Sesion a{
			padding: 8px;
			font-size: 20px;
		}
				.Sesion a:hover{
					cursor: pointer;
				}

			.Sesion.Iniciado a{
				font-size: 2.4vw;
			}

		.Sesion a:last-child{
			padding-right: 24px;
		}

		.Menu{
			display: none;
			z-index: 1;
		}
		.Menu img{
			width: 65px;
		}


@media all and (max-device-width: 900px), (max-width: 650px){
	.Contiene-Elementos{
		height: 60px;
	}

		.Envuelve-Cont-Sombra{
			height: 70px;
		}

		.Contiene-Corta{
			height: 70px;
		}

		.Logo-Fondo{
			width: 200px;
			height: 60px;
		}

		.Logo img{
			width: 150px;
		}

	.MenuNav.Muestra{
		display: flex;
		height: auto;
	}
	.MenuNav{
		display: none;
		flex-direction: column;
		position: absolute;
		width: 250px;
		background-color: var(--base-naranja);
		height: fit-content;
		right: 0;
		top: 70px;
	}

		.Menu{
			display: block;
		}

		.MenuNav .Opciones{
			flex-direction: column;
		}

		.Opciones{
			width: 100%;
			height: 80%;	
		}

		.Opciones a{
			width: 80%;
			background-color: white;
			margin: 8px;
			font-size: 32px;
			padding: 5px;
			box-sizing: border-box;
			height: 45px;
		}

		.Sesion.Iniciado a{
			font-size: 7vw;
		}
		.Sesion a{
			color: #ffffff;
			font-size: 3.5vw;
		};

	/** boton unete */
	.PosBoton .Envuelve-btn{
		bottom: -20px;
	}

	.PosBoton .Envuelve-btn .Btn{
		width: 150px;
		height: 50px;
		font-size: 28px;
		letter-spacing: 5px;
	}
	
}

</style>