import Vue from 'vue';
export default{
    state:{
        sIdCheckout:'',
        oPaquetesDisponibles:{"Individual":[],"Pareja":[]},
        aCarritoPaquetes:[],
        aPagosRealizados:[],
        aGastosRealizados:[],
        aEstadoResultados:[]
    },
    getters:{
        getIdCheckout(state){
            return state.sIdCheckout;
        },
        getPaquetesDisponibles(state){
            return state.oPaquetesDisponibles;
        },
        getCarritoPaquetes(state){
            return state.aCarritoPaquetes;
        },
        getTotalCarrito(state){
            let nTotal=0;
            state.aCarritoPaquetes.forEach(oPakg=>{
                nTotal+=Number(oPakg.Precio);
            })
            return nTotal;
        },
        getPagosRealizados(state){
            return state.aPagosRealizados;
        },
        getGastosRealizados(state){
            return state.aGastosRealizados;
        },
        getEstadoResultados(state){
            return state.aEstadoResultados;
        }
    },
    mutations:{
        setAvailablePackages(state, oDatos){
            let aPaquetes = oDatos.Paquetes,
                aBeneficios = oDatos.Beneficios
            state.oPaquetesDisponibles={};


            aPaquetes.forEach(oPakg => {//almacena registro de progreso
                //agrega seccion tipo paquete
                if(oPakg.TipoPaquete in state.oPaquetesDisponibles == false){
                    state.oPaquetesDisponibles[oPakg.TipoPaquete]=[];
                }
                //Lectura de beneficios
                oPakg.Beneficios = aBeneficios.filter(oBeneficio => {
                                        return oBeneficio.Id_Paquete == oPakg.Id_Paquete;
                                    });
                Vue.set(state.oPaquetesDisponibles[oPakg.TipoPaquete], 
                            state.oPaquetesDisponibles[oPakg.TipoPaquete].length, oPakg);
            });
        },
        setStorePkgCart(state, aDatos){
            state.aCarritoPaquetes=[];
            aDatos.forEach(oPakg => {//almacena
                Vue.set(state.aCarritoPaquetes, state.aCarritoPaquetes.length, oPakg);
            });
        },
        setCheckouId(state, sIdCheckout){
            state.sIdCheckout = sIdCheckout;
        },
        resetAvailablePackages(state){
            state.oPaquetesDisponibles={};
        },
        setPaymentsMade(state, aDatos){
            state.aPagosRealizados=[];
            aDatos.forEach(oPayment => {//almacena 
                Vue.set(state.aPagosRealizados, state.aPagosRealizados.length, oPayment);
            });
        },
        setExpensesMade(state, aDatos){
            state.aGastosRealizados=[];
            aDatos.forEach(oExpense => {//almacena datos 
                Vue.set(state.aGastosRealizados, state.aGastosRealizados.length, oExpense);
            });
        },
        setStoreState(state, aDatos){
            state.aEstadoResultados=[];
            aDatos.forEach(oData => {//almacena datos 
                Vue.set(state.aEstadoResultados, state.aEstadoResultados.length, oData);
            });
        },
    },
    actions:{
        SET_AVAILABLE_PACKAGES(context, oDatos){
            context.commit('setAvailablePackages', oDatos);
        },
        SET_PAYMENTS_MADE(context, oDatos){
            context.commit('setPaymentsMade', oDatos);
        },
        SET_EXPENSES_MADE(context, oDatos){
            context.commit('setExpensesMade', oDatos);
        },
        SET_STORE_STATE(context, oDatos){
            context.commit('setStoreState', oDatos);
        },
        SET_PACKAGES_CART(context, aDatos){
            context.commit('setStorePkgCart', aDatos)
        },
        SET_CHECKOUT_ID(context, sIdCheckout){
            context.commit('setCheckouId', sIdCheckout);
        },
        RESET_AVAILABLE_PACKAGES(context){
            context.commit('resetAvailablePackages');
        }
    }  
}