<template>
  <div id="app">
    <NavTop></NavTop>
    <router-view/>
    <Footer></Footer>
    <dialogs-wrapper></dialogs-wrapper>
  </div>
</template>

<script>
import NavTop from './components/NavTop.vue';
import Footer from './components/Footer.vue';
import MessageBox from './components/Dialog.vue';
export default {
    name: 'App',
    components: {
        'NavTop': NavTop,
        'Footer':Footer,
        'dialogs-wrapper':MessageBox
    },
}
</script>

<style>
    @import './assets/css/ClasesBasicas.css';
    @import './assets/css/variables.css';
    html, body, #app{
      height: 100%;
    }

  /** BOTONES **/
    .Btn{
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .Btn:hover{
      cursor: pointer;
    }

    /**** PAGINA CALENDARIO ****/
    /** INPUT BUSQUEDA **/
    #FechaBusqueda{
        background-color: var(--base-gris-muy-bajo);
        border: none;
        font-size: 28px;
        font-family: 'Bebas';
        padding: 15px 8px 4px;
    }
    
    #FechaBusqueda label{
        top: -2px;
    }
</style>
