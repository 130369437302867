<template>
  <!--    AGREGA OPCION PARA AVISOS -->
  <div id="Avisos" class="ajax-popup-content-holder" v-if="getShowDialog">
    <div class="ajax-popup-content">
      <div class="box">
        <div class="head box-gradient-modal-header text-center">
          <h2 id="TituloMensaje" class="text">{{ getDialogTitle }}</h2>
        </div>
        <div class="content">
          <div id="AvisoMensaje" class="status-message">
            <span v-html="getDialogMsg"></span>
            <div id="almacenaLoader" v-if="getShowLoader">
              <img src="../assets/ImagenesPagina/loader2.gif">
            </div>
            <div id="CodigoUsuario" v-if="getShowCodigoUsuario">
              <input type="password" v-model="codigoUsuarioX">
            </div>
          </div>

          <div id="AvisoBotones" class="modal-button-bar">			
            <div class="button-pagar" v-if="getShowBtnSecondary" @click="closeDialog('Secondary')">{{getBtnSecondaryText}}</div>
            <div class="button-pagar" v-if="getShowBtn" @click="closeDialog()">{{ getBtnMainText }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--    AGREGA OPCION PARA AVISOS -->
</template>

<script>
export default {
    name:'dialogs-wrapper',
    data () {
      return {        
      }
    },
    created:function(){        
    },
    methods: {
        closeDialog:function (sSecondary) {          
          if (this.$store.state.showCodigoUsuario)
            this.$store.dispatch('changeNumpadStatus', false);

          this.$store.dispatch('changeDialogStatus', false);
          if (this.$store.getters.getShowBtnSecondary)
            this.$store.dispatch('changeDialogBtnSecondaryStatus', false);

          if (this.$store.getters.getFunctionDialogEnd !== null && sSecondary !== 'Secondary')
            this.$store.getters.getFunctionDialogEnd();

          if (this.$store.getters.getFunctionDialogEndSecondary !== null && sSecondary == 'Secondary')
            this.$store.getters.getFunctionDialogEndSecondary();

          this.$store.dispatch('setFuncDialogEnd', null);
        },
        onkey (event) {
            // process event...
            let charCode = (event.which) ? event.which : event.keyCode;                
            if(charCode==13 && this.$store.state.showDialog && !this.$store.getters.getProcesandoCaptura){                
                this.closeDialog();
            }                                                  
        }
    },
    computed: {
		getDialogTitle:function () {
            return this.$store.getters.getDialogTitle;
        },
        getShowDialog:function () {
            return this.$store.getters.getShowDialog;
        },
        getDialogMsg:function () {
            return this.$store.getters.getDialogMsg;
        },
        getShowLoader:function () {
            return this.$store.getters.getShowLoader;
        },
        getShowBtn:function () {
            return this.$store.getters.getShowBtn;
		},
		getShowBtnSecondary:function () {
            return this.$store.getters.getShowBtnSecondary;
        },
        getShowCodigoUsuario:function(){
            return this.$store.getters.getShowCodigoUsuario;
		},
		getBtnMainText:function(){
			return this.$store.getters.getBtnMainText;
		},
		getBtnSecondaryText:function(){
			return this.$store.getters.getBtnSecondaryText;
		}
    },
    watch : {
      getShowDialog (newValue){        
          if(newValue){
            window.addEventListener('keydown', this.onkey);
          }
          else{
            window.removeEventListener('keydown', this.onkey);
          }
      }

    }
}
</script>

<!-- Add "scoped" shader.pngattribute to limit CSS to this component only -->
<style scoped>

  /***** CLASES PARA MENSAJE AVISO *****/
  .ajax-popup-content-holder {
    background-image: url(../assets/ImagenesPagina/shader.png);
    position: fixed;    
    display: block;
    z-index: 0;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }



  .ajax-popup-content-holder {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 1000;
  }

  .ajax-popup-content-holder .ajax-popup-content {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    max-height: 95%;
    max-width: 95%;
    overflow: auto;
    text-align: left;
    border-radius: 10px;
  }


  .ajax-popup-content-holder:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .ajax-popup-content {
    -webkit-transition-property: margin-left;
    -moz-transition-property: margin-left;
    -o-transition-property: margin-left;
    transition-property: margin-left;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    position: relative;
  }

  .ajax-popup-content.PopupInicia{
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  .ajax-popup-content.PopupInicia.Efecto{
    opacity: 1;
    transform: scale(1);
  }

  .ajax-popup-content .box .content {
    padding: 0 40px 20px;
    border: 0;
    background: #f6f6f6;
    max-width: 1600px;
    min-width: 250px;
    box-sizing: border-box;
  }

  .ajax-popup-content .box .head .text {
    font-family: 'Bebas';
    color: var(--base-naranja);
    font-size: 50px;
    background: 0;
    padding: 2px;
    margin: 0;
    text-align: center;
    letter-spacing: 4px;
  }

  .ajax-popup-content {
    -webkit-transition-property: margin-left;
    -moz-transition-property: margin-left;
    -o-transition-property: margin-left;
    transition-property: margin-left;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    position: relative;
  }


  .ajax-popup-content .box {
    -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.75);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.75);
    vertical-align: middle;
    position: relative;
    margin: 0 10px 15px;
  }

  .ajax-popup-content .status-message {
    font-size: 24px;
    text-align: center;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .box .content {
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 9px 9px 14px 9px;
    border: 1px solid #ccc;
    border-top: 0;
    background: #fff;
  }

  .box-gradient-modal-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #f6f6f6;
  }

  .modal-button-bar {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.modal-button-bar .button-pagar  {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 35px;
    padding: 9px 24px;
    min-width: 200px;
    font-size: 24px;
    text-decoration: none;
    text-align: center;
    font-family: 'Bebas';
    vertical-align: middle;
    letter-spacing: 2px;
    background-color: var(--base-naranja);
    color: var(--base-blanco);
  }


  div.modal-button-bar .button-pagar:hover {    
    color: #ffffff;
    cursor: pointer;
  }
  div.modal-button-bar .button-pagar:active {        
    outline: 0;
    -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  }

  .modal-button-bar .btn {
    float: right;
    margin-left: 10px;
  }

	.modal-button-bar .button-pagar {
		margin: 0 10px;
	}

  /***** TERMINA CLASES PARA MENSAJE AVISO *****/
  @media all and (max-device-width: 900px), (max-width: 650px){
    .ajax-popup-content .box .content{
      padding: 0 20px 20px;
    }

    .ajax-popup-content .box .head .text{
      font-size: 40px;
    }

    .modal-button-bar{
      flex-direction: column;
    }

    div.modal-button-bar .button-pagar:first-child{
      margin: 0 0 10px;
    }
  }
</style>
