import Vue from 'vue';
export default{
    state:{
        aListaInstrucciones:[],
        aUltimoRegistro:[]
    },
    getters:{
        getListaInstrucciones(state){
            return state.aListaInstrucciones;
        },
        getUltimoRegistro(state){
            return state.aUltimoRegistro;
        },
    },
    mutations:{
        setListaInstrucciones(state, aDatos){
            state.aListaInstrucciones=[];
            aDatos.forEach(oDato => {//almacena
                Vue.set(state.aListaInstrucciones, state.aListaInstrucciones.length, oDato);
            });
        },
        setUltimoRegistro(state, aDatos){
            state.aUltimoRegistro=[];
            aDatos.forEach(oDato => {//almacena
                Vue.set(state.aUltimoRegistro, state.aUltimoRegistro.length, oDato);
            });
        },
        setResetUltimoRegistro(state){
            state.aUltimoRegistro=[];
        }
    },
    actions:{
        SET_LAST_LOG(context, oDatos){
            context.commit('setUltimoRegistro', oDatos);
        },
        SET_INSTRUCTIONS(context, aDatos){
            context.commit('setListaInstrucciones', aDatos)
        },
        RESET_LAST_LOG(context){
            context.commit('setResetUltimoRegistro');
        }
    }  
}