import Vue from 'vue';
import Vuex from 'vuex';

import mainoptions from './modules/mainoptions'
import userInfo from './modules/userInfo'
import calendar from './modules/calendar'
import payments from './modules/payments'
import dialog from './modules/dialog'
import log from './modules/log'


Vue.use(Vuex);
/*eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */


export const store = new Vuex.Store({
    // strict:true,
    modules:{
        mainoptions,
        userInfo,
        dialog,
        calendar,
        payments,
        log
    }
});