<template>
	<section class="gs-section">
		<div class="ImagenMain">
			<img src="../assets/ImagenesPagina/Main.jpg" alt="tecnoforceMain">
		</div>
		<frase-electro tipoImagen="Rojo"></frase-electro>
		<div v-if="!bSesionIniciada" class="PosBoton">
			<div class="Envuelve-btn">
				<div class="Btn" @click="Registrar">ÚNETE</div>
			</div>
		</div>
		<servicios></servicios>
		<div class="CtnFil centrado">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.365728881487!2d-100.982106!3d25.4594605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868813c93e7d7475%3A0xcb028ecef1c2bb65!2sTECNO%20FORCE%20EMS!5e0!3m2!1ses!2smx!4v1676904152578!5m2!1ses!2smx" 
				width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
		</div>
	</section>
</template>


<script>
/*eslint no-console: ["error", { allow: ["warn","log"] }] */
import FraseElectro from '../components/FraseElectro.vue';
import Servicios from '../components/Servicios.vue';

export default {
	name: 'pagina-inicial',
	components: {
        'Servicios': Servicios,
		'FraseElectro':FraseElectro
    },
    methods: {       
		Registrar:function(){
			this.$router.push({ name: 'Registro' });
		}
	},
	computed:{
		bSesionIniciada:{
			get(){
				return this.$store.getters.getSesionIniciada;
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.ImagenMain{
		max-height: 50vw;
		overflow: hidden;
	}
	.ImagenMain img{
		width: 100%;
	}

	.PosBoton{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		position: relative;
		
	}

	.PosBoton .Envuelve-btn{
		position: absolute;
		bottom: -40px;
		transition: 0.2s filter linear;
		filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.8));
	}

	.PosBoton .Envuelve-btn:hover{
		filter: drop-shadow(0 0 0 #44444444);
	}

	.Btn{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 400px;
		height: 80px;
		background-color: var(--base-naranja);
		-webkit-clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
		clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
		margin-right: 20px;

		color: #ffffff;
		font-size: 46px;
		font-weight: bold;
		letter-spacing: 12px;
	}

	@media all and (max-device-width: 950px), (max-width: 650px){
		.PosBoton .Envuelve-btn{
			bottom: -20px;
		}
		.Btn{
			width: 150px;
			height: 50px;
			font-size: 32px;
			letter-spacing: 3px;
		}
	}
</style>

